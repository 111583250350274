export const MahaLogo = require("./libs/assets/logo.png");
export const MhLogo = require("./libs/assets/mh-logo.png");
export const MpbcdcLogo = require("./libs/assets/mpbcdc-logo.png");
export const IndiaLogo = require("./libs/assets/india-logo.jpg");
export const NoticeIcon = require("./libs/assets/cardnotice.jpg");
export const LoginIcon = require("./libs/assets/nbrLogoLogin.png");
export const MahapreitFav = require("./libs/assets/mahapreitlogo.jpg");
export const NbrLogo = require("./libs/assets/nbrlogo.png");
export const Mahogani = require("./libs/assets/mahogani.jpg");
export const Housing = require("./libs/assets/housing.jpg");
export const Construction = require("./libs/assets/construction.jpg");
export const CookingStove = require("./libs/assets/cooking.jpg");
export const AffordableHousing = require("./libs/assets/affordablehousing.png");
export const EmergingTechnology = require("./libs/assets/emergingtechnology.png");
export const EnergyAudit = require("./libs/assets/energyaudit.png");
export const RenewableEnergy = require("./libs/assets/renewableenergy.png");
export const SoftwareTechnology = require("./libs/assets/softwareTechnology.png");
export const WomenEnterpreuner = require("./libs/assets/womenenterpreneur.png");
export const AgroProcessing = require("./libs/assets/agroprocessing.png");
export const Infrastructure = require("./libs/assets/infrastructure.png");
export const Environment = require("./libs/assets/environement.png");
export const Corporate = require("./libs/assets/corporate.png");
export const RegisterPage = require("./libs/assets/register.png");
export const emptyPage = require("./libs/assets/emptyPage.jpg");
export const Slider1 = require("./libs/assets/slider1.jpg");
export const Slider2 = require("./libs/assets/slider2.jpg");
export const Slider3 = require("./libs/assets/slider3.jpg");
export const Slider4 = require("./libs/assets/slider4.jpg");
export const RequiredDocIMGA = require("./libs/assets/002.png");
export const RequiredDocIMGB = require("./libs/assets/003.png");
export const RequiredDocIMGC = require("./libs/assets/004.png");
export const RequiredDocIMGD = require("./libs/assets/005.png");
export const RequiredDocIMGE = require("./libs/assets/006.png");
export const PhotographIMG = require("./libs/assets/photograph.png");
export const SignatureIMG = require("./libs/assets/signature.png");
export const ForgotPass = require("./libs/assets/forgotps.png");
export const BhugolFavicon = require("./libs/assets/favicon.png");
export const HomePageFirstRow = require("./libs/assets/Aurangabad 1.jpg");
export const RegisterBackground = require("./libs/assets/RegisterBackground.jpeg");
export const Landscape = require("./libs/assets/landscape.jpg");
export const NSFDCLogo = require("./libs/assets/nsfdc-logo.png");
export const NSKFDCLogo = require("./libs/assets/nskfdc-logo.png");
export const Manual_Scavengers_List_1 = require("./libs/assets/Manual_Scavengers_List_1.jpg");
export const Manual_Scavengers_List_2 = require("./libs/assets/Manual_Scavengers_List_2.jpg");
export const Manual_Scavengers_List_3 = require("./libs/assets/Manual_Scavengers_List_3.jpg");
