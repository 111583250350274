import React from "react";
import { Container, Heading, ImageContainer } from "./style";
import {
  Manual_Scavengers_List_1,
  Manual_Scavengers_List_2,
  Manual_Scavengers_List_3,
} from "../../../media";

// just a comment to push from bhugolgis id
// comment 2

const NamastePortal = (props) => {
  return (
    <Container>
      <Heading>
        <h1>Below are the details of Manual Scavengers</h1>
      </Heading>
      <ImageContainer>
        {/* <img src={Slider4} height="600px" width="100%" /> */}
        <img
          src={Manual_Scavengers_List_1}
          alt="Manual Scavengers Detail - 1"
        />
        <img
          src={Manual_Scavengers_List_2}
          alt="Manual Scavengers Detail - 2"
        />
        <img
          src={Manual_Scavengers_List_3}
          alt="Manual Scavengers Detail - 3"
        />
      </ImageContainer>
    </Container>
  );
};
export { NamastePortal };
