import { Row, Card } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  margin: 20px;
`;
export const Heading = styled(Row)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; // Space after the heading
`;

export const Cards = styled(Card)`
  width: 300px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;

  img {
    width: 80%;
    max-width: 800px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
`;
